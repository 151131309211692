import { render, staticRenderFns } from "./ABPAppLogin.vue?vue&type=template&id=1f71ee28&scoped=true&"
import script from "./ABPAppLogin.vue?vue&type=script&lang=js&"
export * from "./ABPAppLogin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f71ee28",
  null
  
)

export default component.exports