<template>
  <div>
    <!-- <h1>Добро пожаловать домой!</h1>
        <h2>Здесь выведем элементы рабочего стола</h2> -->
    <!-- {{ abpSelectArr }}
    {{ abpSelect }}
    <abp-select
      v-for="i in [1, 2, 3, 4, 5, 6, 7]"
      :key="`s${i}`"
      v-model="abpSelectArr[i]"
      table="sotrudniks"
      :with-chips="false"
      :title="`Сотрудник${i}`"
      :required="true"
      :multiple="false"
      :readonly="false"
      :editable="true"
      hint="Выберите сотрудника"
    ></abp-select> -->
    <!-- {{ abpSelectN }}
    <abp-select v-model="abpSelectN" table="nomenklatura"></abp-select> -->

    <!-- <abp-filters
            table="nomenklatura"
            v-model="filters"
        >
        </abp-filters> -->
    <!-- <abp-table
            table="nomenklatura"
            :selectable="true"
            :editable="false"
            :multiSelect="false"
            v-model="ts"
            :use-data-array="true"
            :data-array="tableData"
            :modelMixin="stockBalanceMixins"
            @getData="getTableData"
        ></abp-table> -->
    <!-- {{ts}}
        <abp-table
            table="sklad_receives"
            :selectable="false"
            :editable="true"
            :multiSelect="false"
            v-model="ts"
        ></abp-table> -->
    <!-- {{ k1 }}
    <kontragent-form
      table="kontragents"
      :id="751"
      mod-type="edit"
      v-model="k1"
    ></kontragent-form> -->
    <!-- <abp-tabs
            v-model="tabsVal"
            :tabs="tabs"
        >
            <template v-slot:[formSlot]>
                <abp-field
                    v-model="v"
                    :settings="settings"
                    v-bind="settings"
                ></abp-field>
            </template>
        </abp-tabs> -->

    <!-- {{ nomenklaturaId }}
    <abp-field
      v-model="nomenklaturaId"
      :settings="nomenklaturaSelectSettings"
    ></abp-field> -->
    <!-- {{ skladId }}
    <abp-field v-model="skladId" :settings="skladSelectSettings"></abp-field>
    {{ o1 }} {{ ostatkiSettings }}
    <abp-field v-model="o1" :settings="ostatkiSettings"></abp-field>
    {{ o2 }} {{ ostatkiSettings }}
    <abp-field v-model="o2" :settings="ostatkiSettings"></abp-field> -->
    <!-- <stock-balance-input
      v-model="o1"
      v-bind="ostatkiSettings"
      title="Остаток"
    ></stock-balance-input> -->

    <!-- <abp-field
            v-model="skladId"
            :settings="settings1"
        ></abp-field> -->
    <!-- <stock-balance-input
      v-model="o1"
      :skladId="skladId"
      title="Остаток"
    ></stock-balance-input> -->

    <!-- HomeValues={{formVal}} -->
    <!-- <sklad-move-form
            v-model="formVal"
            :id="2"
            modType="edit"
        ></sklad-move-form> -->

    <!-- {{ f1 }}
    <abp-foreign-input
      v-model="f1"
      :structure="foreignStructure"
      :required="true"
      title="Рецепт"
    ></abp-foreign-input> -->

    <!-- <production-replace-form
            v-model="replace"
            title="Форма замены"
        ></production-replace-form> -->

    <!-- <abp-production-items-table
            :data="p_data"
        ></abp-production-items-table> -->

    <!-- {{serials}}
        <abp-nomenklatura-series
            v-model="serials"
            table="production_components"
            id="300"
            :kolvo="1"
        >
        </abp-nomenklatura-series> -->
    <!-- <abp-waiting-message>
          Подождите, нам нужно много чего еще сделать чтобы вы смогли все это увидеть....
        </abp-waiting-message> -->
    <!-- <abp-table-files-extension
          table="nomenklatura"
          id="141"
          type="image"
        >
        </abp-table-files-extension> -->
    <!-- {{ n1 }}
    <abp-field v-model="n1" :settings="settingsN1"></abp-field> -->
    <!-- {{ invoiceForm }}
    <simple-invoice-form v-model="invoiceForm"></simple-invoice-form> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import SimpleInvoiceFormVue from "../Forms/SimpleInvoiceForm.vue";
// import ABPSelectVue from "../Form/ABPSelect.vue";

// import ABPField from "../Form/ABPField";
// import ABPFilters from '../Filters/ABPFilters'
// import ABPTable from '../Tables/ABPTable'
// import ABPTabs from '../Misc/ABPTabs'
// import ABPForm from "../Forms/ABPForm";
// import KontragentFormVue from "../Forms/KontragentForm.vue";
// import StockBalanceInput from "../Form/StockBalanceInput";
// import SkladMoveFormVue from '../Forms/SkladMoveForm.vue'
// import ABPForeignInputVue from "../Form/ABPForeignInput.vue";
// import ABPSimpleTableVue from '../Tables/ABPSimpleTable.vue'
// import ABPProductionItemsTableVue from '../Tables/ABPProductionItemsTable.vue'
// import ProductionReplaceFormVue from '../Forms/ProductionReplaceForm.vue'
// import ABPNomenklaturaSeriesVue from '../Form/ABPNomenklaturaSeries.vue'
// import ABPNomenklaturaSeriesEditorVue from '../Form/ABPNomenklaturaSeriesEditor.vue'
// import ABPWaitingMessage from '../Info/ABPWaitingMessage.vue'

export default {
  components: {
    // "abp-select": ABPSelectVue,
    // "abp-field": ABPField,
    // 'abp-filters' : ABPFilters,
    // 'abp-table' : ABPTable,
    // 'abp-tabs' : ABPTabs,
    // "abp-form": ABPForm,
    // "kontragent-form": KontragentFormVue,
    // "stock-balance-input": StockBalanceInput,
    // 'sklad-move-form' : SkladMoveFormVue,
    // "abp-foreign-input": ABPForeignInputVue,
    // 'abp-simple-table' : ABPSimpleTableVue,
    // 'abp-production-items-table': ABPProductionItemsTableVue,
    // 'production-replace-form': ProductionReplaceFormVue,
    // 'abp-nomenklatura-series' : ABPNomenklaturaSeriesVue,
    // 'abp-nomenklatura-series-editor' : ABPNomenklaturaSeriesEditorVue
    // "abp-waiting-message": () => import("../Info/ABPWaitingMessage.vue"),
    // "abp-table-files-extension": () => import ("../Form/ABPTableFilesExtension.vue")
    // "simple-invoice-form": SimpleInvoiceFormVue,
  },
  created() {
    // получим информацию о пользователе
    this.getUserInfo().then(() => {
      this.setTitle(`Привет, ${this.user.name}!`);
    });
  },
  data() {
    return {
      invoiceForm: {},
      abpSelect: null,
      abpSelectObj: {},
      abpSelectArr: [25, 20, 4, 39, null, 28],
      abpSelectN: 3771,
      nomenklaturaId: 240,
      nomenklaturaSelectSettings: {
        name: "nomenklatura_id",
        type: "select",
        title: "Nomenklatura",
        table: "nomenklatura",
        require: true,
      },
      n1: 1,
      settingsN1: {
        name: "nomenklatura_id",
        type: "select",
        title: "Номенклатура",
        table: "nomenklatura",
        require: true,
      },
      f1: 442,
      k1: {},
      series: [],
      serials: [],
      replace: { production_id: 9, component_id: 8, nomenklatura_from_id: 305 },
      // f1: 1,
      foreignStructure: [
        { table: "nomenklatura", title: "изделие" },
        { table: "recipes", title: "рецептуру", key: "nomenklatura_id" },
      ],
      v: {},
      p1: {
        transitable_id: 2,
        transitable_type: "App\\Sotrudnik",
      },
      skladSelectSettings: {
        name: "skladid",
        type: "select",
        title: "Склад",
        table: "sklads",
        require: true,
      },
      skladId: 15,
      o1: 2756,
      o2: 1,
      morphSettings: {
        name: "transitable",
        title: "Через кого",
        type: "morph",
        tables: [
          { table: "sotrudniks", title: "Сотрудника", type: "App\\Sotrudnik" },
          {
            table: "shipping_companies",
            title: "Транспортную компанию",
            type: "App\\ShippingCompany",
          },
        ],
        require: true,
        size: 1,
      },
      settings1: {
        name: "sklad_id",
        type: "select",
        title: "Выбор склада",
        table: "sklads",
        require: true,
      },
      settings: {
        name: "date1",
        type: "date",
        title: "My Date",
        require: true,
      },
      date1: null,
      filters: { doc_type_id: [5, 2, 3] },
      ts: [],
      tabsVal: 0,
      showTabs: true,
      formVal: {},
      selectVal: null,
      stockBalanceMixins: [
        { name: "ostatok", show_in_table: false },
        {
          name: "stock_balance",
          show_in_table: true,
          title: "Остаток",
          type: "kolvo",
        },
      ],
      p_data: {
        id: 11,
        uuid: null,
        name: "",
        comment: "",
        created_at: "2021-04-20T13:08:56.000000Z",
        created_by: 2,
        updated_at: "2021-04-26T11:30:05.000000Z",
        updated_by: 2,
        deleted_by: null,
        is_protected: 0,
        sync_1c_at: null,
        deleted_at: null,
        doc_num: "1",
        doc_date: "2021-04-20",
        is_active: 0,
        sklad_id: 7,
        recipe_id: 5,
        kolvo: "3.000",
        permissions: {
          show: 1,
          copy: 1,
          edit: 1,
          delete: 1,
        },
        recipe:
          "0__ПЭВМ CPU Intel i3 / 8Gb RAM / 240 Gb SSD / Win10 (ПК + Win 10 Home)",
        sklad: "Производственный склад1",
        replaces: [
          {
            id: 10,
            uuid: null,
            name: "",
            comment: "",
            created_at: "2021-04-22T07:04:22.000000Z",
            created_by: 2,
            updated_at: "2021-04-26T11:30:05.000000Z",
            updated_by: 2,
            deleted_by: null,
            is_protected: 0,
            sync_1c_at: null,
            deleted_at: null,
            production_id: 11,
            component_id: 1,
            nomenklatura_from_id: 302,
            nomenklatura_to_id: 308,
            kolvo_from: "1.000",
            kolvo_to: "2.000",
            save_to_recipe: 0,
            permissions: {
              show: 1,
              copy: 1,
              edit: 1,
              delete: 1,
            },
            nomenklatura_from: "0__Модуль памяти DDR-4 4Gb",
            nomenklatura_to: "0__Модуль памяти DDR-4 2Gb",
          },
          {
            id: 9,
            uuid: null,
            name: "",
            comment: "",
            created_at: "2021-04-22T07:04:22.000000Z",
            created_by: 2,
            updated_at: "2021-04-26T11:30:05.000000Z",
            updated_by: 2,
            deleted_by: null,
            is_protected: 0,
            sync_1c_at: null,
            deleted_at: null,
            production_id: 11,
            component_id: 1,
            nomenklatura_from_id: 304,
            nomenklatura_to_id: 307,
            kolvo_from: "1.000",
            kolvo_to: "1.000",
            save_to_recipe: 0,
            permissions: {
              show: 1,
              copy: 1,
              edit: 1,
              delete: 1,
            },
            nomenklatura_from: "0__Windows 10 Home",
            nomenklatura_to: "Linux Ubuntu",
          },
        ],
        nomenklatura_id: 300,
        items: [
          {
            id: 18,
            uuid: null,
            name: "",
            comment: "",
            created_at: "2021-04-20T13:08:56.000000Z",
            created_by: null,
            updated_at: "2021-04-22T07:03:50.000000Z",
            updated_by: 2,
            deleted_by: null,
            is_protected: 0,
            sync_1c_at: null,
            deleted_at: null,
            production_id: 11,
            serial: "3000000000043",
            kolvo: "1.000",
            price: "1.0000",
            summa: "1.0000",
            permissions: {
              show: 1,
              copy: 1,
              edit: 1,
              delete: 1,
            },
            components: [
              {
                id: 1,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 18,
                nomenklatura_id: 305,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Материнская плата ASUS H360M-K2",
                replaces: [
                  {
                    id: 2,
                    uuid: null,
                    name: "",
                    comment: "",
                    created_at: "2021-04-21T14:17:32.000000Z",
                    created_by: 2,
                    updated_at: "2021-04-22T06:46:38.000000Z",
                    updated_by: 2,
                    deleted_by: null,
                    is_protected: 0,
                    sync_1c_at: null,
                    deleted_at: null,
                    production_id: 12,
                    component_id: 1,
                    nomenklatura_from_id: 302,
                    nomenklatura_to_id: 308,
                    kolvo_from: "1.000",
                    kolvo_to: "2.000",
                    save_to_recipe: 0,
                    permissions: {
                      show: 1,
                      copy: 1,
                      edit: 1,
                      delete: 1,
                    },
                    nomenklatura_from: "0__Модуль памяти DDR-4 4Gb",
                    nomenklatura_to: "0__Модуль памяти DDR-4 2Gb",
                  },
                  {
                    id: 7,
                    uuid: null,
                    name: "",
                    comment: "",
                    created_at: "2021-04-21T14:17:32.000000Z",
                    created_by: 2,
                    updated_at: "2021-04-22T06:46:38.000000Z",
                    updated_by: 2,
                    deleted_by: null,
                    is_protected: 0,
                    sync_1c_at: null,
                    deleted_at: null,
                    production_id: 12,
                    component_id: 1,
                    nomenklatura_from_id: 304,
                    nomenklatura_to_id: 307,
                    kolvo_from: "1.000",
                    kolvo_to: "1.000",
                    save_to_recipe: 0,
                    permissions: {
                      show: 1,
                      copy: 1,
                      edit: 1,
                      delete: 1,
                    },
                    nomenklatura_from: "0__Windows 10 Home",
                    nomenklatura_to: "Linux Ubuntu",
                  },
                  {
                    id: 9,
                    uuid: null,
                    name: "",
                    comment: "",
                    created_at: "2021-04-22T07:04:22.000000Z",
                    created_by: 2,
                    updated_at: "2021-04-26T11:30:05.000000Z",
                    updated_by: 2,
                    deleted_by: null,
                    is_protected: 0,
                    sync_1c_at: null,
                    deleted_at: null,
                    production_id: 11,
                    component_id: 1,
                    nomenklatura_from_id: 304,
                    nomenklatura_to_id: 307,
                    kolvo_from: "1.000",
                    kolvo_to: "1.000",
                    save_to_recipe: 0,
                    permissions: {
                      show: 1,
                      copy: 1,
                      edit: 1,
                      delete: 1,
                    },
                    nomenklatura_from: "0__Windows 10 Home",
                    nomenklatura_to: "Linux Ubuntu",
                  },
                  {
                    id: 10,
                    uuid: null,
                    name: "",
                    comment: "",
                    created_at: "2021-04-22T07:04:22.000000Z",
                    created_by: 2,
                    updated_at: "2021-04-26T11:30:05.000000Z",
                    updated_by: 2,
                    deleted_by: null,
                    is_protected: 0,
                    sync_1c_at: null,
                    deleted_at: null,
                    production_id: 11,
                    component_id: 1,
                    nomenklatura_from_id: 302,
                    nomenklatura_to_id: 308,
                    kolvo_from: "1.000",
                    kolvo_to: "2.000",
                    save_to_recipe: 0,
                    permissions: {
                      show: 1,
                      copy: 1,
                      edit: 1,
                      delete: 1,
                    },
                    nomenklatura_from: "0__Модуль памяти DDR-4 4Gb",
                    nomenklatura_to: "0__Модуль памяти DDR-4 2Gb",
                  },
                  {
                    id: 11,
                    uuid: null,
                    name: "",
                    comment: "",
                    created_at: "2021-04-22T12:23:25.000000Z",
                    created_by: 2,
                    updated_at: "2021-04-22T15:15:35.000000Z",
                    updated_by: 2,
                    deleted_by: null,
                    is_protected: 0,
                    sync_1c_at: null,
                    deleted_at: null,
                    production_id: 19,
                    component_id: 1,
                    nomenklatura_from_id: 302,
                    nomenklatura_to_id: 308,
                    kolvo_from: "1.000",
                    kolvo_to: "2.000",
                    save_to_recipe: 0,
                    permissions: {
                      show: 1,
                      copy: 1,
                      edit: 1,
                      delete: 1,
                    },
                    nomenklatura_from: "0__Модуль памяти DDR-4 4Gb",
                    nomenklatura_to: "0__Модуль памяти DDR-4 2Gb",
                  },
                  {
                    id: 12,
                    uuid: null,
                    name: "",
                    comment: "",
                    created_at: "2021-04-22T12:23:25.000000Z",
                    created_by: 2,
                    updated_at: "2021-04-22T15:15:35.000000Z",
                    updated_by: 2,
                    deleted_by: null,
                    is_protected: 0,
                    sync_1c_at: null,
                    deleted_at: null,
                    production_id: 19,
                    component_id: 1,
                    nomenklatura_from_id: 304,
                    nomenklatura_to_id: 307,
                    kolvo_from: "1.000",
                    kolvo_to: "1.000",
                    save_to_recipe: 0,
                    permissions: {
                      show: 1,
                      copy: 1,
                      edit: 1,
                      delete: 1,
                    },
                    nomenklatura_from: "0__Windows 10 Home",
                    nomenklatura_to: "Linux Ubuntu",
                  },
                  {
                    id: 13,
                    uuid: null,
                    name: "",
                    comment: "",
                    created_at: "2021-04-26T09:16:08.000000Z",
                    created_by: 2,
                    updated_at: "2021-04-26T09:16:08.000000Z",
                    updated_by: null,
                    deleted_by: null,
                    is_protected: 0,
                    sync_1c_at: null,
                    deleted_at: null,
                    production_id: 18,
                    component_id: 1,
                    nomenklatura_from_id: 170,
                    nomenklatura_to_id: 155,
                    kolvo_from: "1.000",
                    kolvo_to: "1.000",
                    save_to_recipe: 0,
                    permissions: {
                      show: 1,
                      copy: 1,
                      edit: 1,
                      delete: 1,
                    },
                    nomenklatura_from:
                      "0__Установка операционной системы Windows Home",
                    nomenklatura_to: "Настройка Windos Настройка Windos",
                  },
                ],
              },
              {
                id: 2,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 18,
                nomenklatura_id: 301,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Процессор Intel Core i3",
                replaces: [],
              },
              {
                id: 3,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-22T07:04:24.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 18,
                nomenklatura_id: 302,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Модуль памяти DDR-4 4Gb",
                replaces: [],
              },
              {
                id: 4,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 18,
                nomenklatura_id: 303,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__SSD Kingstone 240Gb SATA-III",
                replaces: [],
              },
              {
                id: 5,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 18,
                nomenklatura_id: 304,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Windows 10 Home",
                replaces: [],
              },
              {
                id: 6,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 18,
                nomenklatura_id: 306,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Сборка ПК",
                replaces: [],
              },
              {
                id: 7,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 18,
                nomenklatura_id: 170,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Установка операционной системы Windows Home",
                replaces: [],
              },
            ],
            nomenklatura: "0__ПЭВМ CPU Intel i3 / 8Gb RAM / 240 Gb SSD / Win10",
          },
          {
            id: 19,
            uuid: null,
            name: "",
            comment: "",
            created_at: "2021-04-20T13:08:56.000000Z",
            created_by: null,
            updated_at: "2021-04-22T07:03:50.000000Z",
            updated_by: 2,
            deleted_by: null,
            is_protected: 0,
            sync_1c_at: null,
            deleted_at: null,
            production_id: 11,
            serial: "3000000000044",
            kolvo: "1.000",
            price: "1.0000",
            summa: "1.0000",
            permissions: {
              show: 1,
              copy: 1,
              edit: 1,
              delete: 1,
            },
            components: [
              {
                id: 8,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 19,
                nomenklatura_id: 305,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Материнская плата ASUS H360M-K2",
                replaces: [],
              },
              {
                id: 9,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 19,
                nomenklatura_id: 301,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Процессор Intel Core i3",
                replaces: [],
              },
              {
                id: 10,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-22T07:04:24.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 19,
                nomenklatura_id: 302,
                kolvo: "0.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Модуль памяти DDR-4 4Gb",
                replaces: [],
              },
              {
                id: 11,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 19,
                nomenklatura_id: 303,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__SSD Kingstone 240Gb SATA-III",
                replaces: [],
              },
              {
                id: 12,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-22T07:04:24.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 19,
                nomenklatura_id: 304,
                kolvo: "0.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Windows 10 Home",
                replaces: [],
              },
              {
                id: 13,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 19,
                nomenklatura_id: 306,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Сборка ПК",
                replaces: [],
              },
              {
                id: 14,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 19,
                nomenklatura_id: 170,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Установка операционной системы Windows Home",
                replaces: [],
              },
            ],
            nomenklatura: "0__ПЭВМ CPU Intel i3 / 8Gb RAM / 240 Gb SSD / Win10",
          },
          {
            id: 20,
            uuid: null,
            name: "",
            comment: "",
            created_at: "2021-04-20T13:08:56.000000Z",
            created_by: null,
            updated_at: "2021-04-22T07:03:50.000000Z",
            updated_by: 2,
            deleted_by: null,
            is_protected: 0,
            sync_1c_at: null,
            deleted_at: null,
            production_id: 11,
            serial: "3000000000045",
            kolvo: "1.000",
            price: "1.0000",
            summa: "1.0000",
            permissions: {
              show: 1,
              copy: 1,
              edit: 1,
              delete: 1,
            },
            components: [
              {
                id: 15,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 20,
                nomenklatura_id: 305,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Материнская плата ASUS H360M-K2",
                replaces: [],
              },
              {
                id: 16,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 20,
                nomenklatura_id: 301,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Процессор Intel Core i3",
                replaces: [],
              },
              {
                id: 17,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-22T07:04:25.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 20,
                nomenklatura_id: 302,
                kolvo: "0.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Модуль памяти DDR-4 4Gb",
                replaces: [],
              },
              {
                id: 18,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 20,
                nomenklatura_id: 303,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__SSD Kingstone 240Gb SATA-III",
                replaces: [],
              },
              {
                id: 19,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-22T07:04:25.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 20,
                nomenklatura_id: 304,
                kolvo: "0.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Windows 10 Home",
                replaces: [],
              },
              {
                id: 20,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 20,
                nomenklatura_id: 306,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Сборка ПК",
                replaces: [],
              },
              {
                id: 21,
                uuid: null,
                name: "",
                comment: "",
                created_at: "2021-04-20T13:08:56.000000Z",
                created_by: null,
                updated_at: "2021-04-20T13:08:56.000000Z",
                updated_by: null,
                deleted_by: null,
                is_protected: 0,
                sync_1c_at: null,
                deleted_at: null,
                production_item_id: 20,
                nomenklatura_id: 170,
                kolvo: "1.000",
                price: "1.0000",
                summa: "1.0000",
                permissions: {
                  show: 1,
                  copy: 1,
                  edit: 1,
                  delete: 1,
                },
                nomenklatura: "0__Установка операционной системы Windows Home",
                replaces: [],
              },
            ],
            nomenklatura: "0__ПЭВМ CPU Intel i3 / 8Gb RAM / 240 Gb SSD / Win10",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions(["setTitle", "getTableStockBalance", "getUserInfo"]),
    getTableData() {
      console.log(`get new table data`);
      this.getTableStockBalance(this.skladId);
    },
  },
  computed: {
    ...mapGetters(["user"]),
    formSlot() {
      if (this.showTabs) return "form";
      else return "default";
    },
    ostatkiSettings() {
      return {
        name: "nomenklatura_id",
        type: "stock_balance",
        title: "Выбор номенклатуры из остатков",
        sklad_id: this.skladId,
        require: true,
      };
    },

    tabs() {
      if (this.showTabs)
        return [
          {
            title: "Поля ввода",
            icon: "mdi-view-list",
            name: "form",
            disabled: false,
          },
          {
            title: "Изображения",
            icon: "mdi-image",
            name: "images",
            disabled: false,
          },
        ];
      else return null;
    },
    tableData() {
      return this.$store.state.stock_balance.tableData[this.skladId] || null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
