import { render, staticRenderFns } from "./ABPApp.vue?vue&type=template&id=24d2171b&scoped=true&"
import script from "./ABPApp.vue?vue&type=script&lang=js&"
export * from "./ABPApp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d2171b",
  null
  
)

export default component.exports