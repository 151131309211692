<template>
      <abp-app></abp-app>
</template>

<script>
import ABPAppVue from './components/ABPApp.vue'

export default {
  components: {
    'abp-app' : ABPAppVue
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">

</style>
